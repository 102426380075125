import React from 'react'
import {Link} from "react-router-dom"
import {Close as CloseIcon,SentimentVeryDissatisfied as SentimentVeryDissatisfiedIcon,Mood as MoodIcon} from '@material-ui/icons'
import {Snackbar,IconButton} from '@material-ui/core'
import '../lib/scss/toast.scss'

const Toast = ({isToastOpen,handleToastClose,handleToastRedeem,toastVariant,copy,uid, pointsReceived}) => {

  let message,style,icon,action
  let duration = 10000

  switch(toastVariant) {
    case "begin":
      message = copy.toast.begin
      break
    // case "missing":
    //   message = copy.toast.missing
    //   break
    // case "taken":
    //   message = copy.toast.taken
    //   style = "toast-warning"
    //   icon = <SentimentVeryDissatisfiedIcon className="white-text" />
    //   break
    // case "invalid":
    //   message = copy.toast.invalid
    //   style = "toast-warning"
    //   icon = <SentimentVeryDissatisfiedIcon className="white-text" />
    //   break
    // case "error":
    //   message = copy.toast.error
    //   style = "toast-warning"
    //   icon = <SentimentVeryDissatisfiedIcon className="white-text" />
    //   break
    // case "closed":
    //   message = copy.toast.closed
    //   break
    case "success":
      message = `Nice! ${pointsReceived} Yeokens have been added to your account.`
      // message = copy.toast.success
      style = "toast-success"
      icon = <MoodIcon className="white-text" />
      action = <Link className="button" to="/account">Discover Yeoken Rewards</Link> //<a onClick={handleToastRedeem} className="transparent-btn">{copy.successBtnLabel}</a>
      duration = 50000
      break
    // case "redeemed":
    //   message = copy.toast.redeemed
    //   style = "toast-success"
    //   icon = <MoodIcon className="white-text" />
    //   duration = 5000
    //   break
    default:
      message = "default copy"
      break
  }
  console.log("toastVariant: ", toastVariant)
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: 'center',
      }}
      open={isToastOpen}
      onClose={handleToastClose}
      autoHideDuration={duration}
      ContentProps={{
        'aria-describedby': 'message-id',
      }}
      message={
        <span className="toast-content">
          <span className="toast-message">
            {message}
          </span>
          <span className="toast-action">
            {action}
          </span>
        </span>
      }
      className={style}
    />
  )
}

export default Toast
