import React from 'react'
import Header from './components/header'
import Welcome from './views/welcome'
import Account from './views/account'
import Scan from './views/scan'
import {  BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import logo from './logo.svg'
import './App.css'

class App extends React.Component {
  state = {
    yeokens: 30,
  }

  handleUpdatedTotalPoints(points) {
      let TotalPoints = this.state.yeokens + points
      this.setState({
        yeokens: TotalPoints
      })
    }

  render() {

    const {yeokens} = this.state
    console.log("App State: ", yeokens)
    return (
      <Router>
        <Switch>
          <Route exact path="/" render={(props) => (
              <Welcome
                yeokens={yeokens} />
          )} />
          <Route exact path="/scan" render={(props) => (
              <Scan
                onUpdateTotalPoints={this.handleUpdatedTotalPoints.bind(this)}
                yeokens={yeokens} />
          )} />
        <Route exact path="/account" render={(props) => (
              <Account
                yeokens={yeokens} />
          )} />
        </Switch>
      </Router>
    );
  }
}

export default App
