import React, { Component } from 'react'
import {BrowserRouter as Router, Link} from "react-router-dom";
import { useParams } from "react-router";
import Header from '../components/header'
import Copy from '../common/copy'
import AccountSection from '../components/accountsection'
import '../lib/scss/account.scss'
import { Container } from "@material-ui/core"
import Footer from '../components/footer'


class Account extends Component {

  state = {
    sku: ""
  }

  componentDidMount(){
    const params = new URLSearchParams(window.location.search)
    let sku = params.get('sku')

    this.setState({
      sku: sku
    })
  }



  render(){
    const {yeokens} = this.props;
    const {sku} = this.state
    console.log(sku);
    return(
      <div className="welcome-view">
        <Header points={yeokens} scanHeader={true} />
          <div className="section hero">
            <div className="image-overlay">
              <div className="fixed-image-wrapper">
                <img data-object-fit="cover" src="https://cdn1.yeovalley.co.uk/app/uploads/wideimage/3c9963b583d6ac3945e710bd95743721.jpeg" alt="" className="hero__img" />
              </div>
              <Container className="welcome-message-wrapper">
                <div className="welcome-message">
                  <h1>Welcome Back</h1>
                  <p>You have {yeokens} Yeokens - Discover what you can do with them below!</p>
                </div>
              </Container>
            </div>
          </div>
          <AccountSection />
          <Footer />
      </div>
    );
  }
}

export default Account
