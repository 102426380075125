import React, { Component } from 'react'
import { Container } from '@material-ui/core'
import Copy from '../common/copy'
import Data from '../data/uids'
// import Config from '../../common/Config'
// import Header from '../../components/header/Header'
import Header from '../components/header'
import Camera from '../components/camera'
import CameraAccess from '../components/camera-access'
import Loading from '../components/loading'
import Toast from '../components/toast'

// import Data from '../data/uids_array'

class Scan extends Component {

    state = {
      isLoaded: true,
      isCameraEnabled: false,
      bottles: [],
      isToastOpen: false,
      toastVariant: "begin",
      uid: "",
      scannedUids: [],
      hasScanned: false,
      yeokens: this.props.yeokens,
      lastPointsReceived: ""
    }

    componentDidMount() {
      this.handleCameraEnabled()
      // this.handleFetchBottles()
    }

    // handleFetchBottles = () => {
    //   fetch(`${Config.apiEndpoint}/bottles`, {
    //     headers: {
    //       'Accept': 'application/json',
    //       'Content-Type': 'application/json'
    //     }
    //   })
    //   .then(res => res.json())
    //   .then(
    //     (data) => {
    //       this.setState({
    //         bottles: data.items,
    //         isLoaded: true
    //       })
    //     },
    //     (error) => {
    //       this.setState({
    //         isLoaded: true,
    //         isError: true
    //       })
    //     }
    //   )
    // }

    handleCameraEnabled = () => {
      // Does the app have permission to use camera?
      if (navigator && navigator.permissions) {
        return navigator.permissions.query({name: 'camera'}).then((result) => {
          if (result.state === "granted") {
            this.setState({
              isCameraEnabled: true,
              isToastOpen: true
            })
          }
        })
      } else {
        this.setState({
          isCameraEnabled: true,
          isToastOpen: true
        })
      }
    }

    handleRequestCamera = () => {
      if (navigator) {
        return navigator.mediaDevices.getUserMedia({video: true})
          .then((p) => {
            console.log('handleRequestCamera:setting state')
            this.setState({
              isCameraEnabled: true,
              isToastOpen: true
            })
          }).catch((err) => {
            // Camera denied
          })
      }
    }

    handleToastClose = () => {
      this.setState({
        isToastOpen: false
      })
    }

    handleChangeToastVariant = (val) => {
      this.setState({
        isToastOpen: true,
        toastVariant: val
      })
    }

    handleUid = (uid) => {
      this.setState({
        toastVariant: "success",
        isToastOpen: true
      })

      const currentPoints = this.state.yeokens

      if (uid){

        let lastPointsReceived = Data.uids[uid].points
        let totalPoints = lastPointsReceived + currentPoints

        this.setState({
          lastPointsReceived: lastPointsReceived
        })

        this.props.onUpdateTotalPoints(lastPointsReceived)
      }
    }

    // handleRedeemPoints = () => {
    //   this.props.handleAddPoints(10)
    //   // this.handleMarkCrownAsRedeemed()
    // }

    // handleMarkCrownAsRedeemed = () => {
    //   let {crown} = {...this.state}
    //   crown.redeemable = false
    //   crown.closed = false
    //   fetch(`${Config.apiEndpoint}/crowns/uid/${crown.uid}`, {
    //     method: "PUT",
    //     headers: {
    //       'Accept': 'application/json',
    //       'Content-Type': 'application/json'
    //     },
    //     body: JSON.stringify(crown)
    //   })
    //   .then(res => res.json())
    //   .then(
    //     (data) => {
    //       this.handleChangeToastVariant('redeemed');
    //     },
    //     (error) => {
    //       // TODO: error handling
    //       this.handleChangeToastVariant('redeemed');
    //     }
    //   )
    // }

    render() {

      const { isLoaded,isCameraEnabled,bottles,isToastOpen,toastVariant,uid } = this.state
      const { yeokens } = this.props

      return(
        <>
          <Header points={yeokens} scanHeader={true} />
            {
              !isLoaded && (
                <Loading />
              )
            }
            {
              isLoaded && isCameraEnabled ? (
                <Camera
                  handleUid={this.handleUid.bind(this)}
                  // handleChangeToastVariant={this.handleChangeToastVariant.bind(this)}
                  // bottles={bottles}
                  />
              ) : (
                <CameraAccess
                  handleRequestCamera={this.handleRequestCamera.bind(this)}
                  copy={Copy.userScan} />
              )
            }
          <Toast
            pointsReceived={this.state.lastPointsReceived}
            copy={Copy.userScan}
            isCameraEnabled={isCameraEnabled}
            isToastOpen={isToastOpen}
            toastVariant={toastVariant}
            handleToastClose={this.handleToastClose.bind(this)} />
        </>
      )
    }
}

export default Scan
