import React, { Component } from 'react'
import {BrowserRouter as Router, Link} from "react-router-dom";
import { useParams } from "react-router";
import Header from '../components/header'
import Copy from '../common/copy'
import '../lib/scss/welcome.scss'
import Provenance_Cows from '../lib/assets/provenance_cows.jpg'
import Tim_Mead from '../lib/assets/tim_mead.jpg'
import { Container } from "@material-ui/core";

import Block from '../components/block'
import Footer from '../components/footer'

class Welcome extends Component {

  state = {
    sku: "",
    isDorset: false
  }

  componentDidMount(){
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let sku = params.get('sku');

    this.setState({
      sku: sku
    })

    if (sku==="dorset"){
      this.setState({
        isDorset: true
      })
    }
  }



  render(){
    const {yeokens} = this.props;
    const {sku, isDorset} = this.state
    console.log(sku);
    return(
      <div className="provenance-view">
        <Header yeokens={yeokens} isDorset={isDorset} />
        <div className="section hero">
          <div className="image-overlay">
            <div className="fixed-image-wrapper">
              {
                sku ? (
                  <img data-object-fit="cover" src={Copy.sku[sku].img} alt="" className="hero__img" />
                ) : (
                  <img data-object-fit="cover" src="https://cdn1.yeovalley.co.uk/app/uploads/wideimage/b9b3fcb8cb3939585ac7216c6e7471f2.jpeg" alt="" className="hero__img" />
                )
              }
            </div>
            <Container className="welcome-message-wrapper">
              <div className="welcome-message">
                <h1>Thanks for scanning <br/>
                {
                  sku ? (
                      <span>{Copy.sku[sku].title}</span>
                  ) : (
                    <span>&nbsp;</span>
                  )
                }
                </h1>
                {
                  // Check if SKU is present?
                  sku ? (
                    <div className="welcome-summary-text">
                      <p>{Copy.sku[sku].sub}</p>
                    </div>

                  ) : (
                    <p>Scan your open Yeo Valley products to earn points!</p>
                  )
                }
                <div className="yeokens-code-dropdown-nav toggle-hidden-container">
                    <Link className="button" to="/scan">
                    {
                      // Check if SKU is present?
                      sku ? (
                        <span>{Copy.sku[sku].btnLabel}</span>
                      ) : (
                        <span>{Copy.loginPage.btnLabel}</span>
                      )
                    }
                  </Link>
                </div>
              </div>
            </Container>
          </div>

        </div>
        <div className="section ">
          <Container className="page-intro">
            <img className="page-intro__img" src={Tim_Mead} alt="Tim Mead" />
            <span>Tim Mead</span>
            <p>{Copy.provenance.intro1}</p>
            <p>{Copy.provenance.intro2}</p>
          </Container>
        </div>

        <Block />
        <Footer />
      </div>
    );
  }
}

export default Welcome
