module.exports = {
  uids: {
    "1111": {
      "redeemed": false,
      "points": 10
    },
    "1234": {
      "redeemed": false,
      "points": 20
    },
    "1234567891": {
      "redeemed": false,
      "points": 20,
      "type": "lemon-curd"
    },
    "9876543210": {
      "redeemed": false,
      "points": 10,
      "type": "rhubarb"
    },
    "5432109876": {
      "redeemed": false,
      "points": 20,
      "type": "natural"
    }
  }
}
