import React from 'react';
import {Link} from "react-router-dom";
import Copy from '../common/copy'
// import '../lib/scss/footer.scss'

const Footer = () => (
  <footer id="site-footer" className="row column section">
    <div className="site-footer-wrapper text-center">
                    <nav className="menu-footer-menu-top-container"><ul id="menu-footer-menu-top" className="menu row text-left footer-top-menu"><li id="menu-item-457" className="column small-6 medium-3 menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-457"><a>Things We Make</a>
<ul className="sub-menu">
	<li id="menu-item-469" className="column large-6 menu-item menu-item-type-taxonomy menu-item-object-bf-products-range menu-item-469"><a href="https://www.yeovalley.co.uk/things-we-make/yogurt/">Yogurt</a></li>
	<li id="menu-item-463" className="column large-6 menu-item menu-item-type-taxonomy menu-item-object-bf-products-range menu-item-463"><a href="https://www.yeovalley.co.uk/things-we-make/compote/">Compote</a></li>
	<li id="menu-item-466" className="column large-6 menu-item menu-item-type-taxonomy menu-item-object-bf-products-range menu-item-466"><a href="https://www.yeovalley.co.uk/things-we-make/butter/">Butter</a></li>
	<li id="menu-item-464" className="column large-6 menu-item menu-item-type-taxonomy menu-item-object-bf-products-range menu-item-464"><a href="https://www.yeovalley.co.uk/things-we-make/cream/">Cream</a></li>
	<li id="menu-item-465" className="column large-6 menu-item menu-item-type-taxonomy menu-item-object-bf-products-range menu-item-465"><a href="https://www.yeovalley.co.uk/things-we-make/ice-cream/">Ice Cream</a></li>
	<li id="menu-item-467" className="column large-6 menu-item menu-item-type-taxonomy menu-item-object-bf-products-range menu-item-467"><a href="https://www.yeovalley.co.uk/things-we-make/little-yeos/">Little Yeos</a></li>
	<li id="menu-item-468" className="column large-6 end menu-item menu-item-type-taxonomy menu-item-object-bf-products-range menu-item-468"><a href="https://www.yeovalley.co.uk/things-we-make/milk/">Milk</a></li>
</ul>
</li>
<li id="menu-item-458" className="column small-6 medium-3 menu-item menu-item-type-custom menu-item-object-custom current-menu-ancestor current-menu-parent menu-item-has-children menu-item-458"><a>Explore The Valley</a>
<ul className="sub-menu">
	<li id="menu-item-470" className="column large-6 menu-item menu-item-type-post_type menu-item-object-page menu-item-470"><a href="https://www.yeovalley.co.uk/come-and-visit-us/">Come and Visit Us</a></li>
	<li id="menu-item-474" className="column large-6 menu-item menu-item-type-post_type menu-item-object-page menu-item-474"><a href="https://www.yeovalley.co.uk/the-organic-garden/">The Organic Garden</a></li>
	<li id="menu-item-473" className="column large-6 menu-item menu-item-type-post_type menu-item-object-page menu-item-473"><a href="https://www.yeovalley.co.uk/the-farm/">Running The Farm</a></li>
	<li id="menu-item-6202" className="column large-6 menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-6107 current_page_item menu-item-6202"><a href="https://www.yeovalley.co.uk/our-organic-farms/">Our Organic Farms</a></li>
	<li id="menu-item-476" className="column large-6 menu-item menu-item-type-post_type menu-item-object-page menu-item-476"><a href="https://www.yeovalley.co.uk/recipes/">Recipes</a></li>
	<li id="menu-item-472" className="column large-6 menu-item menu-item-type-post_type menu-item-object-page menu-item-472"><a href="https://www.yeovalley.co.uk/our-cows/">Our Cows</a></li>
	<li id="menu-item-475" className="column large-6 menu-item menu-item-type-post_type menu-item-object-page menu-item-475"><a href="https://www.yeovalley.co.uk/the-canteen/">The Canteen</a></li>
	<li id="menu-item-45017" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-45017"><a href="https://www.yeovalley.co.uk/the-cafe/">The Café</a></li>
	<li id="menu-item-471" className="column large-6 end menu-item menu-item-type-post_type menu-item-object-page menu-item-471"><a href="https://www.yeovalley.co.uk/yeo-and-our-communities/">Yeo and our Communities</a></li>
</ul>
</li>
<li id="menu-item-459" className="column small-6 medium-3 menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-459"><a>Things to Do and Win</a>
<ul className="sub-menu">
	<li id="menu-item-478" className="column menu-item menu-item-type-post_type menu-item-object-page menu-item-478"><a href="https://www.yeovalley.co.uk/yeokens/">Yeokens</a></li>
	<li id="menu-item-4406" className="column menu-item menu-item-type-post_type menu-item-object-page menu-item-4406"><a href="https://www.yeovalley.co.uk/yeokens/dairy-go-round/">Dairy-Go-Round</a></li>
	<li id="menu-item-4405" className="column menu-item menu-item-type-post_type menu-item-object-page menu-item-4405"><a href="https://www.yeovalley.co.uk/yeokens/raffle/">Monthly Raffle</a></li>
	<li id="menu-item-5153" className="column menu-item menu-item-type-post_type menu-item-object-page menu-item-5153"><a href="https://www.yeovalley.co.uk/kids/">Children’s</a></li>
	<li id="menu-item-46204" className="column menu-item menu-item-type-post_type menu-item-object-page menu-item-46204"><a href="https://www.yeovalley.co.uk/valleyfest/">Valley Fest</a></li>
</ul>
</li>
<li id="menu-item-460" className="column small-6 medium-3 menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-460"><a>About Us</a>
<ul className="sub-menu">
	<li id="menu-item-3147" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3147"><a href="https://www.yeovalley.co.uk/blog/">Blog</a></li>
	<li id="menu-item-2761" className="column menu-item menu-item-type-custom menu-item-object-custom menu-item-2761"><a href="/faqs">FAQs</a></li>
	<li id="menu-item-32" className="column menu-item menu-item-type-post_type menu-item-object-page menu-item-32"><a href="https://www.yeovalley.co.uk/get-in-touch/">Get in Touch</a></li>
	<li id="menu-item-50127" className="column menu-item menu-item-type-custom menu-item-object-custom menu-item-50127"><a href="https://careers.yeovalley.co.uk">Jobs</a></li>
	<li id="menu-item-461" className="column menu-item menu-item-type-post_type menu-item-object-page menu-item-461"><a href="https://www.yeovalley.co.uk/press/">Press</a></li>
</ul>
</li>
</ul></nav>            <nav role="navigation" className="gutter-bottom--small">
        <ul className="menu social-networks">
                            <li>
                    <a href="https://www.facebook.com/YeoValley/" target="_blank" className="icon-facebook"></a>
                </li>
                            <li>
                    <a href="https://twitter.com/yeovalley/" target="_blank" className="icon-twitter"></a>
                </li>
                            <li>
                    <a href="https://www.instagram.com/yeovalley/" target="_blank" className="icon-instagram"></a>
                </li>
                    </ul>
    </nav>
            <div className="gutter-bottom--small">
                <nav className="menu-footer-menu-bottom-container"><ul id="menu-footer-menu-bottom" className="menu"><li id="menu-item-40" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-40"><a href="https://www.yeovalley.co.uk/cookies-policy/">Cookies Policy</a></li>
<li id="menu-item-42" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-42"><a href="https://www.yeovalley.co.uk/privacy-policy/">Privacy Policy</a></li>
<li id="menu-item-43" className="tracking menu-item menu-item-type-custom menu-item-object-custom menu-item-43"><a target="_blank" href="https://cdn1.yeovalley.co.uk/app/uploads/2019/01/Signed-Yeo-Valley-Modern-Slavery-Statement-2019.pdf">Modern Slavery Statement</a></li>
<li id="menu-item-456" className="tracking menu-item menu-item-type-custom menu-item-object-custom menu-item-456"><a target="_blank" href="https://cdn1.yeovalley.co.uk/app/uploads/2019/03/Yeo-Valley-Gender-Pay-Report-2018.pdf">Gender Pay Gap Report</a></li>
<li id="menu-item-5331" className="tracking menu-item menu-item-type-custom menu-item-object-custom menu-item-5331"><a target="_blank" href="https://cdn1.yeovalley.co.uk/app/uploads/2019/05/yeo-valley-production-tax-strategy-2019.pdf">Yeo Valley Production Tax Strategy</a></li>
<li id="menu-item-39" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-39"><a href="https://www.yeovalley.co.uk/unsubscribe/">Unsubscribe</a></li>
</ul></nav>            </div>

                    <div className="gutter-bottom--small no-orphan">
                <p>Yeo Marketing Limited, Yeo Valley HQ, Rhodyate, Blagdon, Bristol, BS40&nbsp;7YE
Company Registered in England and Wales with registered number 10743193</p>
            </div>
    </div>
</footer>
)

export default Footer
