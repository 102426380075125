import React from 'react';
import {Link} from "react-router-dom";
import Copy from '../common/copy'
import '../lib/scss/header.scss'

// const Header = ({ points, scanHeader }) => (
//   scanHeader ? (
//     <header className="scan">
//       <div className="points">
//         <p>You have {points} Yeokens</p>
//       </div>
//       <div className="burger-menu"><a href="#">BG</a></div>
//     </header>
//   ) : (
//     <header>
//       <h1 className="header__logo brand">
//           <img src="https://www.yeovalley.co.uk/app/themes/yeo/assets/img/base/logo.png" alt="Yeo Valley - Family Farm" />
//       </h1>
//     </header>
//   )
// )

function Header({ points, scanHeader, isDorset }){

  if (scanHeader) {
    return(
      <header className="scan">
        <div className="points">
          <p>You have {points} Yeokens</p>
        </div>
        <div className="burger-menu"><a href="#">BG</a></div>
      </header>
    )
  } else if (isDorset) {
    return(
      <header>
        <h1 className="header__logo brand">
            <img className="yeo__logo" src="https://www.yeovalley.co.uk/app/themes/yeo/assets/img/base/logo.png" alt="Yeo Valley - Family Farm" />
        </h1>
        <img className="dorset__logo" src="https://www.dorsetcereals.co.uk/app/themes/dorset/assets/img/src/colour-schemes/logos/logo-muesli-simply-delicious.png" alt="Dorset Cereals" />

      </header>
    )
  } else {
    return(
      <header>
        <h1 className="yeo__logo" className="header__logo brand">
            <img src="https://www.yeovalley.co.uk/app/themes/yeo/assets/img/base/logo.png" alt="Yeo Valley - Family Farm" />
        </h1>
      </header>
    )
  }
}

export default Header
