import React from 'react';
import {Link} from "react-router-dom";
import Copy from '../common/copy'
// import '../lib/scss/footer.scss'

const Footer = () => (
  <div className="full-width-content-block">
    <div className="row content-block flexible-content standard-row gutter-bottom">

        <div className="column medium-4 content-block__item feature-caption favourite" data-fav-id="p2824">
    <div className="image-overlay">


        <a href="/yeokens/spend" className="block-link" >


            <div className="favourite__item">
    <div className="favourite__info"></div>

    <div className="favourite__image"></div>
</div>

            <div className="fixed-image-wrapper">
                <img data-object-fit="cover" src="https://cdn1.yeovalley.co.uk/app/uploads/wideimage/4eaca1f130c9130d42e4069121707c3c.jpeg"                         alt="Farm tour" />
            </div>


                <h3 className="image-overlay__title"><span className="no-orphan">Spend Your Yeokens</span></h3>



        </a>


    </div>

    <div className="caption">
        <div className="caption__content">
            <div className="no-orphan">
                <p>Spend your Yeokens on visits to the Valley, goodies &amp; discounts.</p>
            </div>

                    </div>
    </div>
</div>
<div className="column medium-4 content-block__item feature-caption favourite" data-fav-id="p2826">
    <div className="image-overlay">


        <a href="/yeokens/donate" className="block-link" >


            <div className="favourite__item">
    <div className="favourite__info"></div>

    <div className="favourite__image"></div>
</div>

            <div className="fixed-image-wrapper">
                <img data-object-fit="cover" src="https://cdn1.yeovalley.co.uk/app/uploads/wideimage/1b78038c532fca6a075d75e6cf0b6a9a.jpeg"                         alt="" />
            </div>


                <h3 className="image-overlay__title"><span className="no-orphan">Donate Your Yeokens</span></h3>



        </a>


    </div>

    <div className="caption">
        <div className="caption__content">
            <div className="no-orphan">
                <p>Convert your Yeokens into cash for one of our featured charities.</p>
            </div>

                    </div>
    </div>
</div>
<div className="column medium-4 content-block__item feature-caption favourite" data-fav-id="p2828">
    <div className="image-overlay">


        <a href="/yeokens/play" className="block-link" >


            <div className="favourite__item">
    <div className="favourite__info"></div>

    <div className="favourite__image"></div>
</div>

            <div className="fixed-image-wrapper">
                <img data-object-fit="cover" src="https://cdn1.yeovalley.co.uk/app/uploads/wideimage/7014a883a0fb1656c6dbed89d069b7d5.jpeg"                         alt="" />
            </div>


                <h3 className="image-overlay__title"><span className="no-orphan">Win With Your Yeokens</span></h3>



        </a>


    </div>

    <div className="caption">
        <div className="caption__content">
            <div className="no-orphan">
                <p>Use your Yeokens to play games, enter competitions and win prizes.</p>
            </div>

                    </div>
    </div>
</div>

    </div>

</div>
)

export default Footer
