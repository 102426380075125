// import Config from "../../common/Config";
// import get from "lodash.get";
import { Grid } from "@material-ui/core";
import jsQR from "jsqr";
import React, { Component } from "react";
import { debounce } from 'throttle-debounce';
import '../lib/scss/camera.scss'
// import "url-search-params-polyfill";

class Camera extends Component {
  constructor(props) {
    super(props);

    this.video = null;
    this.canvas = null;
    this.ctx = null;

    this.camWidth = 400;
    this.camHeight = 600;
    this.cameraRefresh = 250;

    this.state = {
      // endpointURL: "https://us-central1-sharpend.cloudfunctions.net/automl-proxy",
      // currentBottle: {},
      qrData: {},
      // crown: null,
    };

    // Debounce scan handler to prevent multiple consecutive submissions
    this.handleScanData = debounce(500, true, this.handleScanData);
  }

  componentDidMount() {
    if (this.checkHasCamera()) {
      this.video = document.getElementById("Camera");
      this.video.setAttribute("autoplay", "");
      this.video.setAttribute("muted", "");
      this.video.setAttribute("playsinline", "");

      this.canvas = document.createElement("canvas");
      this.canvas.width = this.camWidth;
      this.canvas.height = this.camHeight;
      this.ctx = this.canvas.getContext("2d");

      this.startCamera();
    }
  }

  checkHasCamera = () => {
    // Get access to the camera!
    return navigator.mediaDevices && navigator.mediaDevices.getUserMedia;
  };

  startCamera = () => {
    navigator.mediaDevices
      .getUserMedia({
        video: {
          facingMode: "environment"
        }
      })
      .then(stream => {
        this.video.srcObject = stream;
        setInterval(this.handleCameraStream, this.cameraRefresh);
      });
  };

  handleCameraStream = () => {
    this.ctx.drawImage(this.video, 0, 0);
    const imgData = this.ctx.getImageData(0, 0, this.camWidth, this.camHeight);
    this.handleScan(jsQR(imgData.data, this.camWidth, this.camHeight));
  };

  handleScan = (data) => {
    if (data && data.data) {
      this.handleScanData(data);
    }
  };

  handleScanData = (data) => {
    console.log("Scan!");

    // Parse and unpack data/url
    const url = data.data;
    const qs = url.split("?")[1];
    const params = new URLSearchParams(qs);

    // Go and find the bottle from QR Code
    // const sku = params.get("sku");
    const uid = params.get("uid")
    console.log("UID: ", uid)

    // const currentBottle = this.props.bottles.find(item => {
    //   return item.sku === sku;
    // });

    this.props.handleUid(uid);
    // this.setState({
    //   // currentBottle,
    //   qrData: data
    // });

    // Query database to see status of QR code
    // this.handleFetchCrownData(uid);
  };

  handleError = (err) => {
    console.error(err);
  };

  // handleMLCall = () => {
  //   const bytes = this.canvas.toDataURL("image/jpeg", 0.9).replace("data:image/jpeg;base64,", "");
  //   let data = {
  //     payload: {
  //       image: {
  //         imageBytes: bytes
  //       }
  //     }
  //   };
  //   fetch(this.state.endpointURL, {
  //     headers: {
  //       "Accept": "application/json",
  //       "Content-Type": "application/json"
  //     },
  //     method: "post",
  //     body: JSON.stringify(data)
  //   }).then(res => {
  //     res.json().then((data) => {
  //       const result = get(data, "payload[0].displayName");
  //       if (result === "crown_off") {
  //         console.log("Crown off ✓");
  //         this.props.handleChangeToastVariant("success")
  //       } else if (result === "crown_on") {
  //         console.log("Crown on ✗");
  //         this.props.handleChangeToastVariant("closed")
  //       } else {
  //         console.log("Something else happened ¯\_(ツ)_/¯");
  //       }
  //     });
  //   });
  // };

  // handleFetchCrownData = (uid) => {
  //   fetch(`${Config.apiEndpoint}/crowns/uid/${uid}`, {
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json"
  //     }
  //   })
  //   .then(res => res.json())
  //   .then(
  //     data => {
  //       // If QR code is valid and unopened look for cap
  //       if (data.message === "Crown found") {
  //         // Crown is found so set next steps
  //         if (!data.crown.redeemable) {
  //           // Crown is already redeemed
  //           this.props.handleChangeToastVariant("error");
  //         } else {
  //           if (!data.crown.closed) {
  //             // Crown is already open
  //             this.props.handleChangeToastVariant("taken");
  //           } else {
  //             // Valid crown so proceed to check cap status
  //             console.log("OK, consulting shaman");
  //             this.handleMLCall();
  //           }
  //         }
  //         this.props.handleCrown(data.crown);
  //       } else {
  //         this.props.handleChangeToastVariant("invalid");
  //       }
  //     },
  //     error => {
  //       // TODO
  //     }
  //   );
  // };

  render() {
    return (
      <Grid id="CameraContainer" container className="camera_container">
        <div className="overlay" />
        <video id="Camera" className="camera" />
      </Grid>
    );
  }
}

export default Camera;
