module.exports = {
  loginPage: {
    message: "Hey, bartender! Log in to earn points in our exclusive on-trade experience",
    btnLabel: "Scan Code"
  },
  userScan: {
    enable: "You must allow camera permissions to scan bottles",
    btnLabel: "Allow camera access",
    successBtnLabel: "Discover Yeokens",
    toast: {
      begin: "Look for the QR code on the lid and scan to start earning points",
      missing: "Align the QR code to the box highlighted",
      taken: "Hmmm. This bottle has already been recorded as open. Try another",
      invalid: "Hmmm. This bottle code was not recognised",
      error: "Hmmm. This bottle has already been scanned",
      closed: "Open the bottle before scanning to earn points",
      success: "Click below to see what you can do with your Yeokens",
      redeemed: "Congrats, your points were redeemed!"
    }
  },
  sku: {
    natural : {
      title: "Yeo Valley Natural",
      sub: "Scan your open Yeo Valley lid to earn points!",
      btnLabel: "Scan Code",
      img: "https://cdn1.yeovalley.co.uk/app/uploads/wideimage/cb32a53ade286847f60a80e14b494c71.jpeg"
    },
    lemonCurd : {
      title: "Yeo Valley Lemon Curd",
      sub: "Scan your open Yeo Valley lid to earn points!",
      btnLabel: "Scan Code",
      img: "https://cdn1.yeovalley.co.uk/app/uploads/wideimage/ec4cb913a5cd102369ecccda50e452e0.jpeg"
    },
    rhubarb : {
      title: "Yeo Valley Rhubarb",
      sub: "Scan your open Yeo Valley lid to earn points!",
      btnLabel: "Scan Code",
      img: "https://cdn1.yeovalley.co.uk/app/uploads/wideimage/49d0847cf91b0c3cbf7396a986f8f3ce.jpeg"
    },
    dorset : {
      title: "You've added cereal to the mix!",
      sub: "You've earned 10 Yeokens!",
      btnLabel: "Scan Another Pack",
      img: "https://dn3p03babliv5.cloudfront.net/app/uploads/wideimage/27ad35b82af7fbb7420d0ff397d28eac.jpeg"
    },
  },
  provenance: {
    intro1: "Yeo Valley started at Holt Farm – where my parents put down roots back in 1961.The river Yeo runs through the valley and this is only 10 miles from where our family have been farming since the 1400s.",
    intro2: "Our farms and cows are farmed organically. We do this not only because products taste great, but because it’s better for our animals, our land, our wildlife and our environment."
  }
}
