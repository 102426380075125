import React from 'react';
import {Link} from "react-router-dom";
import Copy from '../common/copy'
import { Container } from "@material-ui/core";
import '../lib/scss/block.scss'



const Block = ({ yeovalley }) => (
    <div className="block-section">
      <div className="block">
          <div className="image-overlay">
            <div className="fixed-image-wrapper">
              <img src="https://cdn1.yeovalley.co.uk/app/uploads/wideimage/be2612cbfa1d355b5f3fd724132bff30.jpeg" alt="Cows" />
            </div>
            <h3 className="image-overlay__title"><span className="no-orphan">Our Cows</span></h3>
          </div>

          <div className="caption">
            <div className="caption__content">
              <div className="no-orphan">
                  <p>Meet the cows!</p>
              </div>
              <a href="https://www.yeovalley.co.uk/our-cows/" className="button">Find out more</a>
            </div>
          </div>
      </div>

      <div className="block">
          <div className="image-overlay">
            <div className="fixed-image-wrapper">
              <img src="https://cdn1.yeovalley.co.uk/app/uploads/wideimage/4d0001f829c9895e63bdcac92f5d8e7c.jpeg" alt="Cows" />
            </div>
            <h3 className="image-overlay__title"><span className="no-orphan">Running the farm</span></h3>
          </div>

          <div className="caption">
            <div className="caption__content">
              <div className="no-orphan">
                  <p>We're organic, right down to our roots.</p>
              </div>
              <a href="https://www.yeovalley.co.uk/the-farm/" className="button">Read about the farm</a>
            </div>
          </div>
      </div>
    </div>
)

export default Block
